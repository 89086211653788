import React,{useState} from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactJsAlert from "reactjs-alert"
const ContactForm = () => {
    const navigate = useNavigate();
    const [formStatus, setFormStatus] = React.useState('Invia')
    const [status, setStatus] = useState(false);
    const [type, setType] = useState("");
    const [title, setTitle] = useState("");
    const onSubmit = (e) => {
        e.preventDefault()
        setFormStatus('Invio in corso...')
        const { nome, cognome, ragione_sociale,p_iva,telefono,email,messaggio,privacy,marketingtrad,marketingauto,marketingcompany } = e.target.elements
        let conFom = {
            name: nome.value,
            cognome: cognome.value,
            company: ragione_sociale.value,
            piva: p_iva.value,
            phone: telefono.value,
            email: email.value,
            message: messaggio.value,
            accept_privacy : (privacy.checked ? true : false),
            marketingtrad : (marketingtrad.checked ? true : false),
            marketingauto : (marketingauto.checked ? true : false),
            marketingcompany : (marketingcompany.checked ? true : false),
            ip : "",
            url : "https://polodellasostenibilita.eu",
            domain : "polodellasostenibilita.eu"
        }

        var jsonString= JSON.stringify(conFom);

        axios({
            method: "POST",
            url: "https://gestione.tecnosrl.it/api/readLead.php",
            data: jsonString,
            headers: { "Content-Type": "application/json", "Accept":"application/json" },
        })
            .then(response => {
                console.log(response);
                console.log(response.data);
                console.log(response.data.accesso);
                //handle success
                if(response.data.accesso===true){
                    /*setStatus(true);
                    setType("success");
                    setTitle(response.data.message.replace("/n", " - "));
                    setFormStatus('Invia')

                    window.dataLayer.push({'event':'grazie'});*/
                    navigate('/grazie');
                }else{
                    setStatus(true);
                    setType("warning");
                    setTitle(response.data.message);
                    setFormStatus('Invia')
                }
            })

    }

    return (
        <div className='contact-container bg-grey pt-5 pb-3' id="contattaci">
            <div className="container">
                <h2 className="text-center fw-bold">Mettiti in contatto con noi</h2>
                <div className="row">
                    <div className="col-md-12 col-xs-12 text-center">
                        <form id="contact-form" className="contatti shadow p-4 m-3 rounded" onSubmit={onSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control greyInput" id="nome" name="nome" required placeholder="Nome"  />
                                        <label htmlFor="nome">Nome*</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control  greyInput" id="cognome" name="cognome"  placeholder="Cognome"/>
                                        <label htmlFor="cognome">Cognome</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control greyInput" id="ragione_sociale" name="ragione_sociale" placeholder="Ragione Sociale" />
                                        <label htmlFor="ragione_sociale">Ragione Sociale</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="number" className="form-control greyInput" id="p_iva" name="p_iva" required placeholder="P.IVA"/>
                                        <label htmlFor="p_iva">P.IVA*</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control  greyInput" id="telefono" name="telefono"  placeholder="Telefono" />
                                        <label htmlFor="telefono">Telefono</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="email" className="form-control greyInput" id="email" name="email" required placeholder="E-Mail" />
                                        <label htmlFor="email">E-Mail*</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-md-12">
                                    <div className="form-floating">
                                        <textarea className="form-control " placeholder="Messaggio" id="messaggio" name="messaggio" rows="10"></textarea>
                                        <label htmlFor="messaggio">Messaggio</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4 text-start">
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input type="checkbox" value="Y" className="form-check-input" id="privacy" name="privacy" required/>
                                        <small className="form-check-label" for="privacy">Ho preso visione dell'informativa
                                            sulla <a href="https://www.iubenda.com/privacy-policy/90852197" target="_blank" className="link-dark"><strong>Privacy Policy</strong></a> e la <a href="https://www.iubenda.com/privacy-policy/90852197/cookie-policy" target="_blank" className="link-dark"><strong>Cookie Policy</strong></a></small>
                                    </div>
                                    <div className="form-check">
                                        <input type="checkbox" value="Y" className="form-check-input" id="marketingtrad" name="marketingtrad" required/>
                                        <small className="form-check-label" for="marketingtrad">Presto il consenso al
                                            trattamento dei miei dati per finalità di marketing mediante contatto
                                            tradizionale(telefono con operatore, posta)</small>
                                    </div>
                                    <div className="form-check">
                                        <input type="checkbox" value="Y" className="form-check-input" id="marketingauto" name="marketingauto"/>
                                        <small className="form-check-label" for="marketingauto">Presto il consenso al
                                            trattamento dei miei dati per finalità di marketing mediante contatto di
                                            tipo automatizzato (telefono senza operatore, sms, mms, e-mail, fax)</small>
                                    </div>
                                    <div className="form-check">

                                        <input type="checkbox" value="Y" className="form-check-input" id="marketingcompany" name="marketingcompany"/>
                                        <small className="form-check-label" for="marketinggroup">Presto il consenso al
                                            trattamento dei miei dati personali alle altre società facenti parti del
                                            gruppo Tecno, per il trattamento svolto in proprio da parte di queste ultime
                                            per le medesime finalità promozionali</small>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center mt-4">
                                <button className="btn btn-md btn-success pr-4 pl-4" data-style="bottomright" type="submit" data-callback="onSubmit" id="send-mail">{formStatus}
                                </button>
                            </div>
                        </form>
                        <ReactJsAlert
                            status={status} // true or false
                            type={type} // success, warning, error, info
                            title={title}
                            Close={() => setStatus(false)}
                        />
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ContactForm