import React from "react";
import {ReactTitle} from 'react-meta-tags';
import TagManager from 'react-gtm-module'

export default function Grazie() {
    const tagManagerArgs = {
        //gtmId: 'GTM-T9STWS9'
    }
    TagManager.initialize(tagManagerArgs)
    window.dataLayer.push({
        event: 'pageview'
    });

    return (
        <>
            <ReactTitle title="Grazie"/>
            <h1 className="fs-1 fw-bolder text-center p-5 m-5 text-black">GRAZIE</h1>
        </>
    )
}