import React from "react"
import {BrowserRouter as Router, Route,  Routes} from "react-router-dom"
// We will create these two pages in a moment
import HomePage from "./HomePage"
import ThankYou from "./ThankYou"
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import ContactForm from './ContactForm';
import ScrollButton from './ScrollButton';
import Grazie from './Grazie'
import MetaTags from 'react-meta-tags';

export default function App() {
  return (
          <div className="App">
              <MetaTags>
                  <title>Il primo Polo italiano della Sostenibilità – Tecno ESG</title>
                  <meta name="description" content="L’hub strategico dedicato alla sostenibilità d’impresa, il primo in Italia dedicato all’evoluzione sostenibile e digitale delle PMI, italiane ed estere." />
                  <meta name="keywords" content="polo della sostenibilità,sostenibilità d'impresa,valutazione performance esg"/>
                  <meta property="og:title" content="Il primo Polo italiano della Sostenibilità – Tecno ESG" />
              </MetaTags>
              <Header/>
                  <Routes>
                      <Route path="/" element={<><Hub/><Sostenibilita/><Puntidiforza/><Network/><ContactForm/></>} />
                      <Route path="/grazie" element={<><Grazie /></>} />
                  </Routes>
                  <FooterBottom />
              <ScrollButton />
          </div>
  )
}

const TopBar = () =>{
    return (

        <div className='topHeader'>
            <div className="container">
                <div className="float-start"><a href="/"><img alt="logo" src={require("./Logo-Polo-della-sostenibilitaa.png")} width={180}/></a></div>
                <div className="float-end text-success fw-bold"><span className="bg-white rounded-pill p-2 m-1"><a href="tel:800128047" className="text-black text-decoration-none">NUMERO VERDE 800.128.047</a></span></div>
            </div>
        </div>

    )
}

const Navigation = () =>{
    return(

        <nav className="navbar navbar-expand-lg navbar-light bg-light rounded bg-white" aria-label="Twelfth navbar example">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample10" aria-controls="navbarsExample10" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-md-center" id="navbarsExample10">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="./#hub">HUB STRATEGICO</a>
                        </li>
                        <li className="nav-item d-none d-sm-block "><span className="spanMenu">|</span></li>
                        <li className="nav-item">
                            <a className="nav-link" href="./#sostenibilita">SOSTENIBILITÀ D’IMPRESA </a>
                        </li>
                        <li className="nav-item d-none d-sm-block"><span className="spanMenu">|</span></li>
                        <li className="nav-item">
                            <a className="nav-link" href="./#puntidiforza">PUNTI DI FORZA</a>
                        </li>
                        <li className="nav-item d-none d-sm-block"><span className="spanMenu">|</span></li>
                        <li className="nav-item">
                            <a className="nav-link" href="./#network">IL NOSTRO NETWORK</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>


    )
}

const Header = () =>{
    return(
        <div className='header'>
            <TopBar />
            <Navigation />
            <Runner />
        </div>
    );
}

const Runner = () =>{
    return(
        <div className='header'>
            <div className="breadcrumb-area mb-100 banner">
                <div className="container">
                    <div className="breadcrumb-txt">
                        <div className="row">
                            <div className="col-xs-12 col-md-12 col-lg-12 img_header_page">
                                <h1 className="header_title_page">Il primo Polo italiano della Sostenibilità</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const Hub = () =>{
    return(
        <section className='header pt-5 pb-5' id='hub'>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-xs-12"><h1 className="h1">L’HUB STRATEGICO PER LA SOSTENIBILITÀ AZIENDALE</h1></div>
                    <div className="col-md-6 col-xs-12 text-start"><p>Abbiamo sempre creduto che gli interessi economici debbano fondersi con quelli sociali, che la possibilità di accrescere il valore di un’azienda debba garantire al contempo il benessere della comunità. È da questi presupposti, dall’attenzione che da sempre la nostra azienda riserva allo sviluppo sostenibile che prende forma il primo Polo della Sostenibilità italiano.</p>
                        <p>Nato nel dicembre 2021 con la presentazione ufficiale presso la sede di Borsa Italiana, il Polo della Sostenibilità è il progetto creato da Tecno e V-Finance, allo scopo di guidare le PMI italiane ed estere che desiderano rinnovare e valorizzare la propria strategia ESG puntando sulla digitalizzazione e la sostenibilità aziendale.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

const Sostenibilita = () =>{
    return(
        <section className='header pt-5 pb-5 grey' id='sostenibilita'>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-xs-12 text-start">
                        <p>Con il Polo della Sostenibilità abbiamo voluto fondere le nostre competenze industriali e tecnologiche con quelle di reporting di V-Finance, società specializzata in ESG Advisory, finanza sostenibile e mercato dei capitali.
                            La nostra partnership ha permesso di creare un hub strategico per la sostenibilità d’impresa, l’unico in Italia dedicato alle PMI.</p>
                        <p>Gli attuali servizi del Polo della Sostenibilità hanno effetto su ben 11 degli SDGs individuati dalle Nazioni Unite con l’Agenda 2030.</p>
                    </div>
                    <div className="col-md-6 col-xs-12">
                <h1 className="h1">SOSTENIBILITÀ D’IMPRESA: L’UNIONE CHE GENERA VALORE</h1></div>

                    <div className="text-center col-12 pt-4 pb-4"><img alt="sdg" src={require("./sdg.png")} className="img-fluid"/></div>
                </div>

            </div>
        </section>
    );
}

const Puntidiforza = () =>{
    return(
        <section className='header pt-5 pb-5' id='puntidiforza'>
            <div className="container"><div className="row">
                <div className="col-md-6 col-xs-12"><h1 className="h1">DIGITAL PLATFORM ESG E TECNO TALKS: I NOSTRI PUNTI DI FORZA</h1>
                    <iframe allowFullScreen="allowfullscreen" id="i6uhp"
                            src="https://www.youtube-nocookie.com/embed/1kt2bYBtsIE?&amp;list=PLe7Qn8dNrodqyOkz1O2zhDXPjVyTuxNPS"></iframe>
                </div>
                <div className="col-md-6 col-xs-12 text-start">
                <p>Se da un lato forniamo un sistema per misurare le performance ESG dell’azienda, dall’altro promuoviamo le azioni sostenibili e innovative attraverso appuntamenti dedicati alla sostenibilità d’impresa.</p>
                <p>La Digital Platform ESG rappresenta la soluzione dedicata alla misurazione delle performance sostenibili aziendali. Grazie ad essa le imprese che intendono avviare un percorso di rinnovamento e crescita sostenibile possono:
<ul className="text-start">
    <li>Conoscere l’entità delle performance ESG aziendali;</li>
    <li>Verificare i rischi ESG di settore;</li>
    <li> Definire le azioni di miglioramento prioritarie e necessarie a colmare le lacune individuate dalla gap analysis;</li>
    <li>Fissare il percorso strategico e personalizzato per la sostenibilità aziendale.</li>
</ul>
                </p>
                <p>I Tecno Talks rappresentano l’altro punto di forza del Polo della Sostenibilità.
                    Si tratta di un ciclo di appuntamenti dedicati alla promozione della sostenibilità d’impresa e alla divulgazione delle buone pratiche imprenditoriali. Questi appuntamenti prevedono, infatti, la partecipazione costante di esperti di sostenibilità aziendale e di testimonial internazionali, aperti al confronto e al racconto della propria storia di rinascita sostenibile.</p>
            </div>
            </div>
            </div>
        </section>
    );
}

const Network = () =>{
    return(
        <section className='header pt-3 pb-3 grey' id='network'>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-xs-12 text-start">
                        <p>Il Polo della Sostenibilità è un progetto aperto, pronto ad accogliere nella propria rete tutte le realtà che desiderano contribuire al cambiamento, attraverso il proprio background e le proprie competenze. </p>
                    </div>
                    <div className="col-md-6 col-xs-12"><h1 className="h1">UN NETWORK RICCO DI COMPETENZE</h1></div>

                    <div className="text-center col-12 pt-3 pb-3"><img alt="network" src={require("./company.png")} className="img-fluid"/></div>
                </div>
            </div>
        </section>
    );
}

const FooterBottom = () =>{
    return (
        <div className="footer">
            <div className="container pt-3 pb-3">
                <div className="logo-5-10 pt-5 d-none d-sm-block"><img src={require("./Logo-Polo-della-sostenibilita-white.png")} width={280} /></div>
                <div className="text-center text-white align-middle fs-6">
                    <span className="fs-6 pt-3 font12 pb-3">Marchio di proprietà della Società Tecno ESG srl, soggetta ad attività di direzione e coordinamento di Tecno Capital S.r.l. <br /> Sede legale: Via Manzoni, 40 - 20121 Milano - Capitale sociale €50.000,00 | C.F./P. IVA 02173090644<br /><a href="https://www.iubenda.com/privacy-policy/90852197" target="_blank" className="text-white font-12 text-decoration-none">Privacy Policy</a> | <a href="https://www.iubenda.com/privacy-policy/90852197/cookie-policy" target="_blank" className="text-white font-12 text-decoration-none">Cookie Policy</a></span></div>
            </div>
        </div>
    )
}